import { useViewEvent } from '@stitch-fix/event-reporter';
import {
  Box,
  Button,
  Divider,
  ResponsiveSection,
  Text,
} from '@stitch-fix/mode-react';
import { Layout } from '../../components/Layout';
import { SEOData } from '../../components/SEOData';

const Error404Page = () => {
  const { ref, EventProvider } = useViewEvent({
    schema: 'view',
    name: 'error_page',
  });

  return (
    <Layout>
      <EventProvider>
        <SEOData
          title="Not Found | Personal Styling for Women & Men"
          description="Not Found"
          robots="noindex, nofollow"
        />
        <ResponsiveSection
          as="main"
          // setting this id allows the "Skip to page content" link to render from knit
          id="knit-main-content"
          width={{ sm: 'medium' }}
        >
          <Box ref={ref} my="5rem" style={{ textAlign: 'center' }}>
            <Text setting="title-xlarge">Page not found</Text>
            <Divider variant="item" my="1rem" />
            <Text setting="body-large">
              Like a sad closet, it appears this page is empty.
            </Text>
            <Button as="a" href="/" my="1rem">
              Go to our homepage
            </Button>
          </Box>
        </ResponsiveSection>
      </EventProvider>
    </Layout>
  );
};

export default Error404Page;
